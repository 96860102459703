$(document).on('ready turbolinks:load', function() {
  if (typeof tinyMCE != 'undefined') {
    tinyMCE.init({
      selector: "textarea.tinymce",
      menubar: false,
      statusbar:false,
      toolbar: ["bold italic bullist numlist link"],
      plugins: "image, link, autoresize, lists",
    });

    tinyMCE.init({
      selector: "textarea.tinymce-articles",
      menubar: false,
      statusbar:false,
      toolbar: ["h2 h3 bold italic bullist numlist link"],
      plugins: "image, link, autoresize, lists",
    });
  }
});
