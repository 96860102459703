$(document).on('ready turbolinks:load', function() {
  var FILESIZE_IN_BYTES = 1024;

  function imageEditorModalOpen(img, fileInput) {
    if(img instanceof HTMLImageElement || img instanceof HTMLCanvasElement) {
      var cropper = initCropper(img);
      var isProfilePhoto = $('#profile_upload_type').val()

      if(isProfilePhoto === 'profile-upload') {
        $('#aspect-ratio-16-9').hide();
        $('#aspect-ratio-2-3').hide();
      }

      if(cropper) {
        $('#crop').on('click', function(event) {
          event.preventDefault();

          var filename = "cropped_" + Date.now() + ".png"

          cropper.getCroppedCanvas().toBlob(function(blob) {
            var file = new File([blob], filename, { type: "image/png", lastModified: new Date().getTime()})
            var dataTransfer = new DataTransfer();

            // Manually set the file size
            Object.defineProperty(file, 'size', { value: FILESIZE_IN_BYTES });

            dataTransfer.items.add(file);

            imageEditorModalClose(dataTransfer.files, fileInput)
          })
        })

        $('#aspect-ratio-16-9').on('click', function(event) {
          event.preventDefault();
          cropper.setAspectRatio(16 / 9);
        })

        $('#aspect-ratio-2-3').on('click', function(event) {
          event.preventDefault();
          cropper.setAspectRatio(2 / 3);
        })

        $('#rotate-pos-45').on('click', function(event) {
          event.preventDefault();
          cropper.rotate(45);
        })

        $('#rotate-neg-45').on('click', function(event) {
          event.preventDefault();
          cropper.rotate(-45);
        })

        $('#use-original').on('click', function(event) {
          event.preventDefault();

          var filename = "cropped_" + Date.now() + ".png"

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0);
          canvas.toBlob(blob => {
            var file = new File([blob], filename, { type: "image/png", lastModified: new Date().getTime() })
            var dataTransfer = new DataTransfer();

            // Manually set the file size
            Object.defineProperty(file, 'size', { value: FILESIZE_IN_BYTES });


            dataTransfer.items.add(file);

            imageEditorModalClose(dataTransfer.files, fileInput);
          }, "image/png", 0.75);
        })
      }
    }
  }

  function imageEditorModalClose(files, fileInput) {
    if(fileInput && files && files instanceof FileList) {
      var image      = files[0]
      var reader     = new FileReader();

      reader.onload = function(file) {
        var img   = new Image();

        img.src        = file.target.result;
        img.className = "card_image w-icon"

        $('.target').html(img).hide().removeClass('d-none').fadeIn();
      }

      fileInput.files = files;

      reader.readAsDataURL(image);
    }

    $('.modal-backdrop').remove()
  }

  function copyClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;

    navigator.clipboard.writeText(str);

    console.log("Copied to Clipboard: " + str)
  };

  function secondarySnapshot() {
    var $el             = $('.nav-secondary');

    if ($el.length > 0) {
      var div_top         = $el.offset().top;
      var scrollTop       = $(this).scrollTop() - 1;

      if ($(this).scrollTop() < (div_top)) {
        $('.snapshot-reveal').hide();
      } else {
        $('.snapshot-reveal').show();
      }
    }
  }

  function rollup_masonry() {
    const rollups = $( ".rollup")

    i = 0
    $( ".rollup-wrapper" ).each(function() {
      $(this).html(rollups[i])
      i+=1
    });
  };

  function initCropper(img) {
    if(!img.complete || img === null || typeof img === 'undefined') {
      return false
    }

    return new Cropper(img, {
      aspectRatio: 16 / 9,
      viewMode: 1,
      center: true,
      responsive: true,
      checkCrossOrigin:false,
      ready() {
        console.log('cropper is ready')

        var canvas_img = document.getElementsByClassName('cropper-canvas')[0].firstElementChild;
        var original_img = img;
        var canvas_src = canvas_img.getAttribute('src');
        var img_src = original_img.getAttribute('src');

        canvas_img.setAttribute('crossorigin', 'anonymous');
        original_img.setAttribute('crossorigin', 'anonymous');

        /*Somehow src needs to be set again in order for crossorigin to work */
        canvas_img.setAttribute('src', canvas_src);
        original_img.setAttribute('src', img_src);
      }
    });
  }

  function initEditorModal(params) {
    var fileInput = document.getElementById(params.fileInputId);
    var image = params.imageObj || document.getElementById(params.imageId);

    if(image) {
      new bootstrap.Modal(
        document.getElementById('image-editor-modal'), {
          keyboard: false
      }).show();

      $('#image-editor-modal .modal-body')
      .html(image)
      .fadeIn();

      setTimeout(function() {
        imageEditorModalOpen(image, fileInput);
      }, 500)
    }

  }

  rollup_masonry();

  $('#upload-photo-modal #profile_photo').on('change', function(event) {
    if(event.target.files.length > 0) {
      $('#upload-photo-modal input[name="commit"]').removeClass('disabled');
    }
  })

  $('#post-image-thumb').click(function(event) {
    event.preventDefault();

    initEditorModal({
      imageId: 'original-image',
      fileInputId: 'post_post_images_attributes_0_cropped_attachment',
      imageObj: null
    })
  })

  $('#profile-photo-thumb').click(function(event) {
    event.preventDefault();

    initEditorModal({
      imageId: 'original-profile-photo',
      fileInputId: 'profile_cropped_photo',
      imageObj: null
    })
  })

  $('.post-content-preview').each(function(){

    var height = $(this).height();

    if (height >= 260) {
      $(this).addClass('box-inset-bottom');
    }
  });

  $(document).on('change', 'input[name="profile[photo]"]', function(event) {
    var files      = event.target.files;
    var image      = files[0]
    var reader     = new FileReader();

    reader.onload = function(file) {
      var img   = new Image();

      img.src        = file.target.result;

      initEditorModal({
        imageId: null,
        fileInputId: 'profile_cropped_photo',
        imageObj: img
      })
    }

    reader.readAsDataURL(image);
  })

  $(document).on('change', 'input[name="post[post_images_attributes][0][attachment]"]' ,function(event) {
    var files      = event.target.files;
    var image      = files[0]
    var reader     = new FileReader();

    reader.onload = function(file) {
      var img   = new Image();

      img.src        = file.target.result;

      initEditorModal({
        imageId: null,
        fileInputId: 'post_post_images_attributes_0_cropped_attachment',
        imageObj: img
      })
    }

    reader.readAsDataURL(image);
  });

  $('.click-expand').click(function(event){
    event.preventDefault()

    var target   = $(this).data('click-expand-target');

    $(target).toggleClass('expandable-content');

    if ($(target).hasClass('expandable-content')) {
      $(this).html('Expand')
    } else {
      $(this).html('Collapse')
    }
  });

  if ($('.snapshot-reveal').length > 0) {
    window.addEventListener('scroll', secondarySnapshot);
  }

  $('.modal.default-active').each(function(event) {
    var modal_id = $(this).attr('id');

    modal = new bootstrap.Modal(document.getElementById(modal_id), {
      keyboard: false
    })

    setTimeout(function() {
      modal.show();
    }, 1500);
  });

  $('.field-required').change(function(){
    var form             = $(this).closest('form'),
        button           = $('input[type="submit"]', form),
        incomplete_count = 0;

    $('.field-required', form).each(function(i, obj) {
      var empty       = ($(this).val() == ''),
          is_checkbox = $(this).hasClass('boolean'),
          is_checked  = $(this).is(':checked');

      if (empty || (is_checkbox && is_checked == false)) {
        incomplete_count += 1;
      }
    });

    if (incomplete_count == 0) {
      button.removeClass('disabled');
    } else {
      button.addClass('disabled');
    }
  }).change();

  $('.valueAppend').click(function(event){
    event.preventDefault()

    var target = $(this).data('value-append-target'),
        original_value    = $(target).val().split(','),
        additional_value  = String($(this).data('value'));

    console.log(additional_value)
    if (original_value.includes(additional_value)) {

      for( var i = 0; i < original_value.length; i++){
        if ( original_value[i] === additional_value) {
          original_value.splice(i, 1);
        }
      }
      $(this).removeClass('active');
    } else {
      original_value.push(additional_value);
      $(this).addClass('active');
    }

    $(target).val(original_value);
  })

  $('.greater_than').change(function(event){
    var current_value = $(this).val(),
        greater_than = $(this).data('greater-than');

    if (current_value <= greater_than ) {
      $(this).val(greater_than + 1)
    }
  })

  $('.trigger-value').click(function(event){
    event.preventDefault()

    var target = $(this).data('target'),
        val    = $(this).data('value');

    $(target).val(val).change();
    $('.trigger-value').removeClass('active');
    $(this).addClass('active');
  });

  $('.trigger-show').click(function(event){
    event.preventDefault()

    var target = $(this).data('show-target');

    $(target).removeClass('d-none').show();
  });

  $('.trigger-hide').click(function(event){
    event.preventDefault()

    var target = $(this).data('hide-target');

    $(target).addClass('d-none');
  });

  $('.trigger-slideDown').change(function(event){
    var value = $(this).is(":checked"),
        target = $(this).data('trigger-target');

    if (value == true) {
      $(target).removeClass('d-none').slideDown();
    } else {
      $(target).slideUp();
    }
  }).change();

  $('.trigger-activation').change(function(event){
    var value  = $(this).is(":checked"),
        target = $(this).data('trigger-target');

    if (value == true) {
      $(target).addClass('active');
    } else {
      $(target).removeClass('active')
    }
  }).change();

  $('#purchase_profiles_count').change(function(event){
    var price_per_profile = $(this).data('profile-price'),
        count             = $(this).val(),
        total             = parseFloat(price_per_profile) * count;

    $('#purchaseTotal').html("$" + total.toFixed(2));
  }).change();

  $('.trigger-copy').click(function(event){
    event.preventDefault()

    var parent      = $(this).closest('.copy-wrapper'),
        input_value = $('input', parent).val(),
        button      = $('.btn', parent),
        initial_html = button.html();

    console.log(input_value)
    copyClipboard(input_value)

    button.html('Copied to Clipboard!');

    setTimeout(function() {
      button.html(initial_html);
    }, 3000);
  })

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  $('#user_estimated_monthly_net_cashflow').addClass('field-required');

  $('.field-required').change(function(){
    var form             = $(this).closest('form'),
        button           = $('input[type="submit"]', form),
        incomplete_count = 0;

    $('.field-required', form).each(function(i, obj) {
      var empty = ($(this).val() == '');

      if (empty) {
        incomplete_count += 1;

        button.attr('disabled', 'disabled');

        console.log('asdf')
      }
    });

    if (incomplete_count == 0) {
      $('input[type="submit"]', form).removeAttr('disabled');
    }
  }).change();

  $(document).on('change', 'input.currency-input' ,function(event) {
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40){
      event.preventDefault();
    }

    var initial_val = $(this).val();
    var num         = initial_val.split(".");
    var pre_period  = num[0];
    var post_period = num[1];
    var pre_period  = pre_period.toString().replace(/[^0-9]/g, '');
    var pre_period  = pre_period.replace(/,/gi, "");
  	var pre_period  = pre_period.split(/(?=(?:\d{3})+$)/).join(",");

    if (post_period) {
      var post_period = post_period.toString().replace(/[^0-9]/g, '');
    }

    if (post_period && post_period[1]) {
      var joined_num = pre_period + '.' + post_period[0] + post_period[1];
    } else if (post_period && post_period[0]) {
      var joined_num = pre_period + '.' + post_period[0] + '0';
    } else {
      var joined_num = pre_period + ".00";
    }

    $(this).val(joined_num);
  }).change();

  $('#donation_gift_in_dollars').change(function(event){
    var input_value           = $(this).val(),
        roundup_percent       = $(this).data('roundup-percentage'),
        roundup_value         = $(this).val() * roundup_percent,
        total_without_roundup = input_value,
        total_with_roundup    = parseFloat(roundup_value) + parseFloat(input_value),
        without_roundup_html  = "$" + parseFloat(total_without_roundup).toFixed(2),
        with_roundup_html     = "$" + parseFloat(total_with_roundup).toFixed(2);

    $('.roundupTotal').html(with_roundup_html);
  }).change();

  $('.toggle-trigger').click(function(event){
    event.preventDefault();
    target = $(this).data('toggleable');

    if ($(target).hasClass('d-none')) {
      $(target).removeClass('d-none');
    } else {
      $(target).toggle();
    }
  });

  $(".alerts").delay(5000).slideUp(500);

  $('form').one('submit', function(e) {
    event.preventDefault();

    $('.currency-input').each(function() {
      var clean = $(this).val().split(",").join("")

      $(this).val(clean);
    });

    $(this).submit();
  });

  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    return event.preventDefault();
  });

  $('.add_organization .add_fields').click(function(event){
    $(this).hide();
  });

  $('form').on('click', '.add_fields', function(event) {
    var regexp, time;

    time   = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));

    return event.preventDefault();
  });

  if(window.location.href.includes('focus=birth_date')) {
    setTimeout(function() {
      $('.birth-date-picker').each(function() {
        $(this).focus()
      });
    }, 5)
  }

  if(window.location.href.includes('focus=death_date')) {
    setTimeout(function() {
      $('.death-date-picker').each(function() {
        $(this).focus()
      });
    }, 5)
  }

  $('.birth-date-picker').flatpickr({
    altInput: true,
    altFormat: 'F j, Y',
    onOpen: [
      function(_selectedDates, _dateStr, instance) {
        instance.set({ maxDate: $('.death-date-picker').val() })
      }
    ]
  });

  $('.death-date-picker').flatpickr({
    altInput: true,
    altFormat: 'F j, Y',
    onOpen: [
      function(_selectedDates, _dateStr, instance) {
        instance.set({ minDate: $('.birth-date-picker').val() })
      }
    ]
  })

  $('.datetime-picker').flatpickr({
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    altInput: true,
    altFormat: 'F j, Y H:i',
  });

  $("#profile_organization_id").change(function(event) {
    var ids = $("#profile_indirect_fund_organization_ids").val()
    var id = $(this).val()

    if(id.length) {
      ids = ids.concat(',', id)
      $("#profile_indirect_fund_organization_ids").val(ids)
    }
  })

  $('.edit-custom-task').click(function(event) {
    event.preventDefault();

    var task_id = $(this).parent().siblings('#profile_task_ids_').val()

    $('#custom-task-' + task_id).toggleClass('d-none')
  })

  $("input[name='profile[task_ids][]']").change(function(event) {
    var task_id = event.target.value
    var attr_template = 'profile[tasks_attributes][' + task_id + ']'

    if(!event.target.checked) {
      $('input[name="' + attr_template + '[id]"]').val('')
      $('input[name="' + attr_template + '[name]"]').val('')
      $('select[name="' + attr_template + '[task_category_id]"]').val('')
      $('#edit-task-' + task_id).toggleClass('d-none')

      if(!$('#custom-task-' + task_id).hasClass('d-none')) {
        $('#custom-task-' + task_id).toggleClass('d-none')
      }
    } else {
      var task_name = $(this).siblings('#task_name').val()
      var task_category_id = $(this).siblings('#task_category_id').val()

      $('input[name="' + attr_template + '[id]"]').val(task_id)
      $('input[name="' + attr_template + '[name]"]').val(task_name)
      $('select[name="' + attr_template + '[task_category_id]"]').val(task_category_id)
      $('#edit-task-' + task_id).toggleClass('d-none')
      $('#custom-task-' + task_id).addClass('d-none')
    }
  })
});

