$(document).on('turbolinks:before-cache', function () {
  $('.select2').select2('destroy');
  $('.modal .select2').select2('destroy');
});

$(document).on('ready turbolinks:load', function() {
  $('.select2').select2({
    theme: 'bootstrap-5'
  });

  $('.modal .select2').each(function() {
    $(this).select2({
      dropdownParent: this.closest('.modal'),
      theme: 'bootstrap-5'
    });
  });

  $('.select2').on('select2:open', function(event) {
    document.querySelector('.select2-search__field').focus();
  });
});

